<template>
  <el-row>
    <el-col class="container">
      <el-col class="title">我的优惠券</el-col>
      <el-col class="state-list">
        <el-col class="item cursor" :class="{active: active=='0'}" @click.native="checkedState('0')">全部</el-col>
        <el-col class="item cursor" :class="{active: active=='1'}" @click.native="checkedState('1')">已领取</el-col>
        <el-col class="item cursor" :class="{active: active=='2'}" @click.native="checkedState('2')">即将过期</el-col>
        <el-col class="item cursor" :class="{active: active=='3'}" @click.native="checkedState('3')">已过期</el-col>
      </el-col>
      <el-col class="coupon-list">
        <el-col class="coupon-item overdue-bg" v-for="(item,idx) in tableData" :key="idx" >
          <el-col class="coupon-title left" >通用券</el-col>
          <el-col class="coupon-info right">
            <el-col class="coupon-name" :class="couponItem(item.overdue)"> 满{{item.couponCondition}}可立减{{item.couponPrice}}元</el-col>
            <el-col class="validity" :class="couponItem(item.overdue)">有效期：{{item.couponBeginTime}}到{{item.couponEndTime}}</el-col>
            <el-col class="condition" :class="couponItem(item.overdue)" >所有商品可用</el-col>
          </el-col>
        </el-col>
        <el-col v-if="tableData.length==0" class="no-coupon-container">
          <img src="../../img/coupon/bg-coupon.png" class="no-coupon">
          <el-col class="text">暂无优惠券</el-col>
        </el-col>
         <!-- <el-col class="coupon-item currency-overdue">
          <el-col class="coupon-title left">通用券</el-col>
          <el-col class="coupon-info right">
            <el-col class="coupon-name currency-coupon">满999可立减200元</el-col>
            <el-col class="validity currency-coupon">有效期：2020-02-02-2020-06-02</el-col>
            <el-col class="condition currency-coupon">所有商品可用</el-col>
          </el-col>
        </el-col>
        <el-col class="coupon-item activity">
          <el-col class="coupon-title left">活动券</el-col>
          <el-col class="coupon-info right">
            <el-col class="coupon-name activity-coupon">满999可立减200元</el-col>
            <el-col class="validity activity-coupon">有效期：2020-02-02-2020-06-02</el-col>
            <el-col class="condition activity-coupon">仅限指定活动商品可用</el-col>
          </el-col>
        </el-col>
        <el-col class="coupon-item activity-overdue">
          <el-col class="coupon-title left">活动券</el-col>
          <el-col class="coupon-info right">
            <el-col class="coupon-name activity-coupon">满999可立减200元</el-col>
            <el-col class="validity activity-coupon">有效期：2020-02-02-2020-06-02</el-col>
            <el-col class="condition activity-coupon">仅限指定活动商品可用</el-col>
          </el-col>
        </el-col>
        <el-col class="coupon-item hardware">
          <el-col class="coupon-title left">硬件券</el-col>
          <el-col class="coupon-info right">
            <el-col class="coupon-name hardware-coupon">满999可立减200元</el-col>
            <el-col class="validity hardware-coupon">有效期：2020-02-02-2020-06-02</el-col>
            <el-col class="condition hardware-coupon">仅限硬件商品可用</el-col>
          </el-col>
        </el-col>
        <el-col class="coupon-item hardware-overdue">
          <el-col class="coupon-title left">硬件券</el-col>
          <el-col class="coupon-info right">
            <el-col class="coupon-name hardware-coupon">满999可立减200元</el-col>
            <el-col class="validity hardware-coupon">有效期：2020-02-02-2020-06-02</el-col>
            <el-col class="condition hardware-coupon">仅限硬件商品可用</el-col>
          </el-col>
        </el-col>
        <el-col class="coupon-item software">
          <el-col class="coupon-title left">软件券</el-col>
          <el-col class="coupon-info right">
            <el-col class="coupon-name software-coupon">满999可立减200元</el-col>
            <el-col class="validity software-coupon">有效期：2020-02-02-2020-06-02</el-col>
            <el-col class="condition software-coupon">仅限软件商品可用</el-col>
          </el-col>
        </el-col>
        <el-col class="coupon-item software-overdue">
          <el-col class="coupon-title left">软件券</el-col>
          <el-col class="coupon-info right">
            <el-col class="coupon-name software-coupon">满999可立减200元</el-col>
            <el-col class="validity software-coupon">有效期：2020-02-02-2020-06-02</el-col>
            <el-col class="condition software-coupon">仅限软件商品可用</el-col>
          </el-col>
        </el-col>
        <el-col class="coupon-item overdue-bg">
          <el-col class="coupon-title left">软件券</el-col>
          <el-col class="coupon-info right">
            <el-col class="coupon-name overdue">满999可立减200元</el-col>
            <el-col class="validity overdue">有效期：2020-02-02-2020-06-02</el-col>
            <el-col class="condition overdue">仅限软件商品可用</el-col>
          </el-col>
        </el-col>  -->
      </el-col>
      <el-col class="page" v-if="tableData.length > 0">
        <el-pagination
        layout="prev, pager, next"
              :total="page.total"
              class="table-page"
              @current-change="handleCurrentChange"
              :current-page="page.pageNo"
              :page-size="page.pageSize">
        </el-pagination>
      </el-col>
    </el-col>

  </el-row>
</template>

<script>
import coupobApi from '../../api/buyer/myCoupon/coupobApi';
export default {
  name: "coupon",
  data() {
    return {
      active: '0',
      tableData: [],
      overdure: 0,
      page: {
        pageNo: 1,
        pageSize: 16,
        total: 3
      }
    }
  },
  methods: {
    checkedState(index) {
      this.active = index;
      this.getConsoleCouponList();
    },
    // 获取优惠券列表
    getConsoleCouponList() {
        coupobApi.getConsoleCouponList({pageNo: this.page.pageNo, pageSize: this.page.pageSize,overdue: this.active,}).then((res) => {
          if(res.data == null) {
            this.tableData = []
            return;
          }
          this.tableData = res.data.records
          this.page.total = res.data.total
        })
    },
    handleCurrentChange(val) {
      this.page.pageNo = val;
      this.getConsoleCouponList();
    },
  },
  mounted() {
    this.getConsoleCouponList();
  },
   computed:{
        couponItem(){
          return function(item){
          if(item === 3){
            return "overdue"
          }
          if(item === 2){
            return "overdue-bg"
          }
          if(item === 1){
            return "overdue-bg"
          }
        }
     }
  }
}
</script>

<style lang="less" scoped>

  .container {

    .right {
      float: right;
    }

    margin: 0 58px;
    .cursor:hover {
      cursor: pointer;
    }

    .title {
      font-size: 24px;
      color: #F56E65;
      margin: 52px 0;
    }

    .item {
      font-size: 14px;
      color: #414141;
      width: auto;
      margin-right: 70px;
    }

    .active {
      color: #F56E65;
    }
    .no-coupon-container {
      text-align: center;
      width: 100%;
      height: 100%;
      .no-coupon {
        margin-top: 150px;
        width: 350px;
        height: 300px;
        ////background: red;
        //background: url("") no-repeat top center;
      }
      .text{
        margin-top: 20px;
        font-size: 20px;
        font-weight: bold;
        color: #858585;
      }
    }
    .coupon-list {




      .coupon-item {
        margin-right: 60px;
        margin-bottom: 10px;

        .coupon-title {
          font-size: 18px;
          color: #FFFFFF;
          padding: 58px 0 0 24px;
          width: auto;
        }

        .coupon-info {
          padding-top: 37px;
          padding-right: 32px;
          width: 220px;

          .coupon-name {
            font-size: 18px;
            width: auto;
            margin-bottom: 9px;
          }

          .validity {
            font-size: 12px;
            width: auto;
            margin-bottom: 9px;
          }

          .condition {
            font-size: 12px;
            margin-bottom: 9px;
          }

          .currency-coupon {
            color: #F56E65;
          }

          .activity-coupon {
            color: #FFC13F;
          }

          .hardware-coupon {
            color: #6575F5;;
          }

          .software-coupon {
            color: #65D7F5;
          }

          .overdue {
            color: #C3C3C3;
          }
        }
      }

      .currency {
        background: url("../../img/coupon/bg_currency.png") no-repeat top center;
        width: 335px;
        height: 147px;

      }

      .currency-overdue {
        background: url("../../img/coupon/bg_currency_overdue.png") no-repeat top center;
        width: 335px;
        height: 147px;
      }

      .activity {
        background: url("../../img/coupon/bg_activity.png") no-repeat top center;
        width: 335px;
        height: 147px;
      }

      .activity-overdue {
        background: url("../../img/coupon/bg_activity_overdue.png") no-repeat top center;
        width: 335px;
        height: 147px;
      }

      .hardware {
        background: url("../../img/coupon/bg_hardware.png") no-repeat top center;
        width: 335px;
        height: 147px;
      }

      .hardware-overdue {
        background: url("../../img/coupon/bg_hardware_overdue.png") no-repeat top center;
        width: 335px;
        height: 147px;
      }

      .software {
        background: url("../../img/coupon/bg_software.png") no-repeat top center;
        width: 335px;
        height: 147px;
      }

      .software-overdue {
        background: url("../../img/coupon/bg_software_overdue.png") no-repeat top center;
        width: 335px;
        height: 147px;
      }

      .overdue-bg {
        background: url("../../img/coupon/bg_overdue_ex.png") no-repeat top center;
        width: 335px;
        height: 147px;
      }
    }

    .page {
      text-align: center;
    }
  }



</style>
